<template>
    <div>
        <v-card outlined v-show="showMonitor" class="v-card-border-none">
            <v-card-title class="pt-2 pb-2 v-card-custom-title">
                <div
                    class="text-left v-card-custom-title-left"
                    style="width: 65%"
                >
                    <template v-if="!VUETIFY_BREAKPOINT.mobile">
                        <span style="white-space: nowrap"
                            >Large Exposure Volume Monitor<v-tooltip
                                right
                                v-if="configWarning"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        color="red"
                                        small
                                        style="margin-left: 2px"
                                        >mdi-information-outline</v-icon
                                    >
                                </template>
                                <span
                                    >No config for Large Exposure Volume Monitor
                                    can be found.</span
                                >
                            </v-tooltip></span
                        >
                    </template>
                </div>
                <div
                    class="text-right v-card-custom-title-right"
                    style="width: 35%"
                >
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                x-small
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                class="alertBookSelection"
                            >
                                {{ selectedBook }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(item, index) in bookOption"
                                :key="index"
                                dense
                                @click="selectedBook = item"
                            >
                                <v-list-item-title>{{
                                    item
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <template v-if="!VUETIFY_BREAKPOINT.mobile">
                        {{ " " }}
                        <download-excel
                            :data="filteredMonitorData"
                            :fields="csvHeader"
                            style="display: inline"
                            type="csv"
                            name="Large_Exposure_Volume.csv"
                            :escapeCsv="false"
                        >
                            <v-btn
                                icon
                                x-small
                                :disabled="filteredMonitorData.length === 0"
                                color="green"
                                style="margin-right: 2px"
                                class="alertDownload"
                                ><v-icon>mdi-microsoft-excel</v-icon>
                            </v-btn>
                        </download-excel>
                        <v-btn
                            icon
                            x-small
                            color="blue-grey darken-1"
                            v-on:click="
                                getConfigForAlertAction('Large Exposure Volume')
                            "
                            style="margin-right: 2px"
                            class="alertConfig"
                            v-if="showConfig"
                            ><v-icon>mdi-cog</v-icon>
                        </v-btn>
                        {{ " " }}
                        <v-icon
                            small
                            style="top: 2px; color: grey"
                            @click="$emit('onRemove')"
                            class="alertClose"
                            >mdi-close-thick</v-icon
                        >
                    </template>
                </div>
            </v-card-title>

            <v-data-table
                style="max-width: 550px"
                dense
                item-key="name"
                :customSort="customSort"
                :items="filteredMonitorData"
                :headers="monitorHeader"
                class="elevation-0 alert-datatable"
                :items-per-page.sync="offset"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :height="height - 120"
                fixed-header
                :header-props="{ sortIcon: null }"
                :page.sync="currentPage"
                :mobile-breakpoint="0"
                :footer-props="{
                    'items-per-page-text': '',
                    'items-per-page-options': [30, 50, 100, 300, 500, 1000],
                }"
            >
                <template v-slot:item="{ item }">
                    <tr>
                        <td column="login">
                            <a
                                v-on:click="loadDialog(item)"
                                v-if="showHomeLoginDetailSummary"
                                style="display: inline-block; height: 21px"
                                >{{ item.login }}</a
                            >

                            <div v-else>
                                {{ item.login }}
                            </div>
                        </td>
                        <td
                            column="name"
                            align="left"
                            v-if="
                                selectedProfile.settings['largeExposureVolume'].name
                                    .showing
                            "
                        >
                            {{ item.name }}
                        </td>
                        <td
                            column="books"
                            v-if="
                                selectedProfile.settings['largeExposureVolume']
                                    .books.showing
                            "
                        >
                            {{ item.books.join(", ") }}
                        </td>
                        <td
                            column="symbol"
                            v-if="
                                selectedProfile.settings['largeExposureVolume']
                                    .symbol.showing
                            "
                        >
                            {{ item.symbol }}
                        </td>
                        <td
                            column="exposureVolume"
                            align="right"
                            v-if="
                                selectedProfile.settings['largeExposureVolume']
                                    .exposureVolume.showing
                            "
                        >
                            {{ item.exposureVolume.toFixed(2) }}
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>

        <LoginSummaryDialog
            v-if="dialog"
            :fullLogin="fullLoginValue"
            :login="loginValue"
            :server="serverValue"
            :symbol="symbolValue"
        />
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import LoginSummaryDialog from "@components/login-summary/ComponentLoginSummaryDialog";
import { helper } from "@components/mixins/helper";
import ChartLargeExposure from "./chart/ComponentLargeExposureChart";
import common from "@assets/js/common";
import deepClone from "deep-clone";
import { permission } from "@components/mixins/permission";
import { getConfig } from "@services/alert/config";

export default {
    mixins: [helper, permission],
    components: { LoginSummaryDialog, ChartLargeExposure },
    props: {
        brokerId: null,
        broker: null,
        height: 0,
        showConfig: false,
    },
    data() {
        return {
            visible: false,
            showAlert: false,
            showMonitor: true,
            dialog: false,
            sortBy: "exposureVolume",
            sortDesc: true,
            bookOption: [],
            recordDate: common.getMT4TimeString().split(" ")[0],
            selectedBook: "B",
            showHomeLoginDetailSummary: false,
            fullLoginValue: null,
            loginValue: null,
            serverValue: null,
            symbolValue: null,
            offset: 30,
            currentPage: 1,
            configWarning: false,
        };
    },
    computed: {
        ...mapState("LargeExposureVolume", [
            "monitorData",
            "monitorHeader",
            "loading",
            "csvHeader",
        ]),
        ...mapState("Alert", ["books", "selectedProfile"]),
        ...mapState("SettingsServer", ["serverData"]),
        filteredMonitorData() {
            return this.monitorData.filter(
                (item) => item.books[0] === this.selectedBook
            );
        },
    },
    watch: {
        showing(nv) {
            if (nv) {
                getConfig("large-exposure-volume").then(({ data }) => {
                    if (data.length === 0) {
                        this.configWarning = true;
                    }
                });
            }
        },
        selectedProfile(nv) {
            if (this.books.length !== 0) {
                if (nv) {
                    this.UPDATE_MONITOR_DATA([]);
                }
                const settings = nv.settings["largeExposureVolume"];
                const columnName = Object.keys(settings);
                let newHeader = deepClone(this.monitorHeader);
                newHeader.map((item) => {
                    if (columnName.includes(item.value)) {
                        item.align = settings[item.value].showing
                            ? ["exposureVolume"].includes(item.value)
                                ? "right"
                                : "left"
                            : " d-none";
                    }
                });
                let filtered = [];
                this.books.map((book) => {
                    if (!nv.unselectedBooks.includes(book)) filtered.push(book);
                });
                this.bookOption = filtered;
                this.UPDATE_MONITOR_HEADER(newHeader);
            }
        },
        books(nv) {
            if (this.selectedProfile.id !== 0) {
                if (nv) {
                    this.UPDATE_MONITOR_DATA([]);
                }
                const settings =
                    this.selectedProfile.settings["largeExposureVolume"];
                const columnName = Object.keys(settings);
                let newHeader = deepClone(this.monitorHeader);
                newHeader.map((item) => {
                    if (columnName.includes(item.value)) {
                        item.align = settings[item.value].showing
                            ? ["exposureVolume"].includes(item.value)
                                ? "right"
                                : "left"
                            : " d-none";
                    }
                });
                let filtered = [];
                this.books.map((book) => {
                    if (!this.selectedProfile.unselectedBooks.includes(book))
                        filtered.push(book);
                });
                this.bookOption = filtered;
                this.UPDATE_MONITOR_HEADER(newHeader);
            }
        },
        bookOption(nv) {
            if (nv.includes("B")) {
                this.selectedBook = "B";
            } else {
                this.selectedBook = nv[0];
            }
        },
    },
    methods: {
        ...mapMutations("LargeExposureVolume", ["UPDATE_MONITOR_HEADER"]),
        ...mapMutations("LargeExposureVolume", ["UPDATE_MONITOR_DATA"]),
        ...mapActions("Config", ["getConfigForAlertAction"]),
        customSort(items, sortBy, sortDesc) {
            items.sort((a, b) => {
                if (!sortDesc[0]) {
                    return a[sortBy] < b[sortBy] ? -1 : 1;
                } else {
                    return b[sortBy] < a[sortBy] ? -1 : 1;
                }
            });
            return items;
        },
        /**
         * Remove string prefix
         *
         * @param   {[type]}  name  [name description]
         * @return  {[type]}        [return description]
         */
        removePrefix(name) {
            return name.split(":")[1];
        },
        loadDialog(item) {
            this.fullLoginValue = item.user;
            this.loginValue = item.login.split(":")[1];
            this.serverValue = item.login.split(":")[0];
            this.symbolValue = item.symbol.split(".")[0];
            this.dialog = false;
            this.$nextTick(() => {
                this.dialog = true;
            });
        },
    },
    created() {
        const userFunction = this.getFunction("book");

        if (userFunction.includes("homeLoginDetailSummary")) {
            this.showHomeLoginDetailSummary = true;
        }
    },
};
</script>

<style scoped>
.v-list-item--dense,
.v-list--dense .v-list-item {
    min-height: 30px;
}
</style>
