<template>
    <v-dialog
        width="1400"
        :hide-overlay="true"
        :persistent="true"
        v-model="configDialog"
    >
        <v-card class="pt-0">
            <v-app-bar
                flat
                color="rgba(0, 0, 0, 0)"
                style="background: rgb(26, 57, 88)"
            >
                <v-toolbar-title class="text-h6 white--text pl-0">
                    {{
                        selectedAlertConfig === "Large Volume"
                            ? "Big Lot"
                            : selectedAlertConfig
                    }}
                    Config
                </v-toolbar-title>
                <v-spacer></v-spacer>

                <v-btn color="white" icon @click="UPDATE_CONFIG_DIALOG(false)"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-app-bar>

            <v-card-actions>
                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12"></v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-data-table
                            id="config-table"
                            fixed-header
                            :items-per-page="15"
                            class="elevation-0"
                            :loading="loading"
                            dense
                            :items="configData"
                            :headers="
                                tableHeader === null
                                    ? {}
                                    : tableHeader.filter(
                                          (item) => item.value !== 'actions'
                                      )
                            "
                            item-key="id"
                            :header-props="{ sortIcon: null }"
                        >
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-actions>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="UPDATE_CONFIG_DIALOG(false)"
                    :disabled="loading"
                    >Cancel</v-btn
                >
                <v-btn
                    color="red darken-1"
                    text
                    @click="toEditConfig(selectedAlertConfig)"
                    :loading="loading"
                    ><span>To Edit</span></v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
    mixins: [snackbar],
    computed: {
        ...mapState("Config", [
            "configDialog",
            "selectedAlertConfig",
            "loading",
            "configData",
            "headerOptions",
        ]),
        tableHeader() {
            if (this.headerOptions[this.selectedAlertConfig]) {
                return this.headerOptions[this.selectedAlertConfig];
            } else {
                return null;
            }
        },
    },
    methods: {
        ...mapMutations("Config", ["UPDATE_CONFIG_DIALOG"]),
        toEditConfig(type) {
            let routeData = this.$router.resolve({
                name: "Settings",
                query: { selected_type: type },
            });
            window.open(routeData.href, "_blank");
        },
    },
};
</script>
