<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-expansion-panels
            multiple
            accordion
            focusable
            :value="alertLists.map((_, idx) => idx)"
        >
            <v-expansion-panel v-for="item in alertLists" :key="item">
                <v-expansion-panel-header>
                    {{ allAlertOptions[item] }}
                </v-expansion-panel-header>
                <v-expansion-panel-content eager>
                    <LargeVolume
                        v-if="item === 'largeVolume'"
                        :brokerId="selectedBrokerId"
                        :broker="selectedBroker"
                        :height="300"
                        :showing="alertLists.includes('largeVolume')"
                        :showConfig="showConfigIcon"
                    />
                    <LargeVolumeUSD
                        v-if="item === 'largeVolumeUSD'"
                        :brokerId="selectedBrokerId"
                        :broker="selectedBroker"
                        :height="300"
                        :showing="alertLists.includes('largeVolumeUSD')"
                        :showConfig="showConfigIcon"
                    />
                    <FastTrade
                        v-if="item === 'fastTrade'"
                        :brokerId="selectedBrokerId"
                        :broker="selectedBroker"
                        :height="300"
                        :showing="alertLists.includes('fastTrade')"
                        :showConfig="showConfigIcon"
                    />
                    <LargeExposure
                        v-if="item === 'largeExposure'"
                        :brokerId="selectedBrokerId"
                        :broker="selectedBroker"
                        :height="300"
                        :showConfig="showConfigIcon"
                    />
                    <LockingPosition
                        v-if="item === 'lockingPosition'"
                        :brokerId="selectedBrokerId"
                        :broker="selectedBroker"
                        :height="300"
                        :showing="alertLists.includes('lockingPosition')"
                        :showConfig="showConfigIcon"
                    />
                    <Mandate
                        v-if="item === 'mandate'"
                        :brokerId="selectedBrokerId"
                        :broker="selectedBroker"
                        :height="300"
                        :showConfig="showConfigIcon"
                    />
                    <ProfitTaker
                        v-if="item === 'profitTaker'"
                        :brokerId="selectedBrokerId"
                        :broker="selectedBroker"
                        :height="300"
                        :showing="alertLists.includes('profitTaker')"
                        :showConfig="showConfigIcon"
                    />
                    <TradeOnCredit
                        v-if="item === 'tradeOnCredit'"
                        :brokerId="selectedBrokerId"
                        :broker="selectedBroker"
                        :height="300"
                        :showing="alertLists.includes('tradeOnCredit')"
                        :showConfig="showConfigIcon"
                    />
                    <WatchList
                        v-if="item === 'watchList'"
                        :brokerId="selectedBrokerId"
                        :broker="selectedBroker"
                        :height="300"
                        :showing="alertLists.includes('watchList')"
                        :showConfig="showConfigIcon"
                    />
                    <DailyWinner
                        v-if="item === 'dailyWinner'"
                        :brokerId="selectedBrokerId"
                        :broker="selectedBroker"
                        :height="300"
                        :showing="alertLists.includes('dailyWinner')"
                        :showConfig="showConfigIcon"
                    />
                    <DailyLoser
                        v-if="item === 'dailyLoser'"
                        :brokerId="selectedBrokerId"
                        :broker="selectedBroker"
                        :height="300"
                        :showing="alertLists.includes('dailyLoser')"
                        :showConfig="showConfigIcon"
                    />
                    <LargeExposureVolume
                        v-if="item === 'largeExposureVolume'"
                        :brokerId="selectedBrokerId"
                        :broker="selectedBroker"
                        :height="300"
                        :showConfig="showConfigIcon"
                    />
                    <DepositWithdrawal
                        v-if="item === 'depositWithdrawal'"
                        :showing="alertLists.includes('depositWithdrawal')"
                        :brokerId="selectedBrokerId"
                        :broker="selectedBroker"
                        :height="300"
                        :showConfig="showConfigIcon"
                    />
                    <SameDirection
                        v-if="item === 'sameDirection'"
                        :brokerId="selectedBrokerId"
                        :broker="selectedBroker"
                        :height="300"
                        :showing="alertLists.includes('sameDirection')"
                        :showConfig="showConfigIcon"
                    />
                    <Tick
                        v-if="item === 'tick'"
                        :brokerId="selectedBrokerId"
                        :broker="selectedBroker"
                        :height="300"
                        :showing="alertLists.includes('tick')"
                        :showConfig="showConfigIcon"
                    />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <AlertConfigDialog />
    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import DepositWithdrawal from "./component/ComponentDepositWithdrawal";
import FastTrade from "./component/ComponentFastTrade";
import LargeVolume from "./component/ComponentLargeVolume";
import LargeVolumeUSD from "./component/ComponentLargeVolumeUSD";
import ProfitTaker from "./component/ComponentProfitTaker";
import SameDirection from "./component/ComponentSameDirection";
import DailyLoser from "./component/ComponentDailyLoser";
import DailyWinner from "./component/ComponentDailyWinner";
import LargeExposure from "./component/ComponentLargeExposure";
import LargeExposureVolume from "./component/ComponentLargeExposureVolume";
import LockingPosition from "./component/ComponentLockingPosition";
import Mandate from "./component/ComponentMandate";
import WeightedVolume from "./component/ComponentWeightedVolume";
import Tick from "./component/ComponentTick";
import TradeOnCredit from "./component/ComponentTradeOnCredit";
import WatchList from "./component/ComponentWatchList";
import AlertConfigDialog from "./ComponentAlertConfigDialog";

import { helper } from "@components/mixins/helper";
import { permission } from "@components/mixins/permission";
import { position } from "@components/mixins/position";

export default {
    components: {
        DepositWithdrawal,
        LargeVolume,
        LargeVolumeUSD,
        ProfitTaker,
        FastTrade,
        LargeExposure,
        WeightedVolume,
        LockingPosition,
        Mandate,
        TradeOnCredit,
        WatchList,
        DailyWinner,
        DailyLoser,
        LargeExposureVolume,
        SameDirection,
        Tick,
        AlertConfigDialog,
    },
    mixins: [helper, position, permission],
    data() {
        return {
            moved: false,
            layout_grid: [],
            standardHeight: 1,
            standardColNum: 36,
            showConfigIcon: false,
            selectedColumnSize: 4,
            columnList: [2, 3, 4, 6],
            visible: false,
            alertLists: [],
            defaultLists: [
                "fastTrade",
                "largeExposure",
                "largeVolume",
                "largeVolumeUSD",
                "lockingPosition",
                "mandate",
                "profitTaker",
                "tradeOnCredit",
                "watchList",
                "dailyWinner",
                "dailyLoser",
                "largeExposureVolume",
                "depositWithdrawal",
                "sameDirection",
                "tick",
            ],
            allAlertOptions: {
                fastTrade: "Fast Trade",
                largeExposure: "Large Exposure",
                largeVolume: "Big Lot",
                largeVolumeUSD: "Large Volume USD",
                weightedVolume: "Weighted Volume",
                lockingPosition: "Locking Position",
                mandate: "Mandate",
                profitTaker: "Profit Taker",
                tradeOnCredit: "Trade On Credit",
                watchList: "Watch List",
                dailyWinner: "Daily Winner",
                dailyLoser: "Daily Loser",
                largeExposureVolume: "Large Exposure Volume",
                depositWithdrawal: "Deposit Withdrawal",
                sameDirection: "Same Direction",
                tick: "Tick",
            },
            unselectedChart: [],
            monitorSocket: null,
            selectedBroker: "",
            selectedBrokerId: -1,
            monitorList: [
                "LARGE_EXPOSURE_VOLUME_MONITOR",
                "LARGE_EXPOSURE_MONITOR",
                "MANDATE_MONITOR",
                "LOCKING_POSITION_MONITOR",
                "DAILY_WINNER_LOSER_MONITOR",
            ],
            dialog: false,
            moveToNext: null,
            toPath: "",
            overlay: false,
        };
    },
    computed: {
        ...mapState("Alert", [
            "settings",
            "selectedProfile",
            "allProfile",
            "loading",
            "clickedOutside",
            "clickedOutside",
            "selectedPeriod",
            "periodOptions",
        ]),
    },
    watch: {
        selectedProfile(nv) {
            this.overlay = false;
            if (nv.unselected.length) {
                this.unselectedChart = nv.unselected
                    .filter((item) => this.defaultLists.includes(item))
                    .sort();
            } else {
                this.unselectedChart = [];
            }
            if (nv.id === 0) {
                this.alertLists = [...this.defaultLists];
            }
            this.getDisplayReady();
        },
    },
    methods: {
        ...mapActions("Alert", [
            "putProfileAction",
            "getBooksPkAction",
            "putUserProfileAction",
        ]),
        ...mapActions("Home", ["getSymbolDPAction"]),
        ...mapActions("FastTrade", ["processFastTrade"]),
        ...mapActions("LargeVolume", ["processLargeVolume"]),
        ...mapActions("LargeVolumeUSD", ["processLargeVolumeUSD"]),
        ...mapActions("WeightedVolume", ["processWeightedVolume"]),
        ...mapActions("LockingPosition", ["processLockingPosition"]),
        ...mapActions("ProfitTaker", ["processProfitTaker"]),
        ...mapActions("TOC", ["processTradeOnCredit"]),
        ...mapActions("WatchList", ["processWatchList"]),
        ...mapActions("DailyWinner", ["processDailyWinner"]),
        ...mapActions("DailyLoser", ["processDailyLoser"]),
        ...mapActions("DepositWithdrawal", ["processDepositWithdrawal"]),
        ...mapActions("SameDirection", ["processSameDirection"]),
        ...mapActions("Tick", ["processTick"]),

        ...mapActions("LargeExposure", ["processLargeExposure"]),
        ...mapActions("Mandate", ["processChartMandate"]),
        ...mapActions("LargeExposureVolume", ["processLargeExposureVolume"]),
        ...mapMutations("Alert", ["UPDATE_REFRESH_PERIOD"]),

        getDisplayReady() {
            this.moved = false;
            const positionSettings = this.selectedProfile.positions;
            this.alertLists = [];
            let copiedList = [...this.defaultLists];
            this.unselectedChart.map((item) => {
                copiedList = copiedList.filter((e) => e !== item);
            });
            this.alertLists = copiedList;
            this.layout_grid = [];

            this.alertLists.map((item) => {
                const itemPos = positionSettings?.find((i) => i.alert == item);
                if (itemPos === undefined) {
                    this.layout_grid.push({
                        x: 0,
                        y: 100000,
                        w: 9,
                        h: 300,
                        i: item,
                    });
                } else {
                    this.layout_grid.push({
                        x:
                            itemPos.posX > this.standardColNum
                                ? (this.layout_grid.length *
                                      (this.standardColNum /
                                          this.selectedColumnSize)) %
                                  this.standardColNum
                                : itemPos.posX,
                        y: itemPos.posY,
                        w:
                            itemPos.width > this.standardColNum
                                ? this.standardColNum / this.selectedColumnSize
                                : itemPos.width,
                        h: itemPos.height,
                        i: item,
                    });
                }
            });
        },
        changeRefreshPeriod(period) {
            this.UPDATE_REFRESH_PERIOD(period);
        },
    },
    created() {
        this.getSymbolDPAction();
        this.moved = false;
        this.getBooksPkAction();
        this.getDisplayReady();
        this.showConfigIcon =
            false ||
            (JSON.parse(
                localStorage.getItem("permission")
            )?.frontPermission?.account?.child?.includes("config") &&
                JSON.parse(
                    localStorage.getItem("permission")
                )?.frontPermission?.settings?.child?.includes("alertConfig"));

        const eventPumpingUrl =
            (document.querySelector("body").getAttribute("apiurl") ||
                process.env.VUE_APP_ENDPOINT) + "/api/v1/pumping";

        var source = new EventSource(eventPumpingUrl);

        source.onmessage = (e) => {
            const IncomingData = JSON.parse(e.data);
            switch (IncomingData.alert_name) {
                case "Fast Trade":
                    this.processFastTrade(IncomingData);
                    break;
                case "Big Lot":
                    this.processLargeVolume(IncomingData);
                    break;
                case "Large Volume USD":
                    this.processLargeVolumeUSD(IncomingData);
                    break
                case "Locking Position":
                    this.processLockingPosition(IncomingData);
                    break;
                case "Profit Taker":
                    this.processProfitTaker(IncomingData);
                    break;
                case "Trade On Credit":
                    this.processTradeOnCredit(IncomingData);
                    break;
                case "Watch List":
                    this.processWatchList(IncomingData);
                    break;
                case "Daily Winner":
                    this.processDailyWinner(IncomingData);
                    break;
                case "Daily Loser":
                    this.processDailyLoser(IncomingData);
                    break;
                case "Deposit Withdrawal":
                    this.processDepositWithdrawal(IncomingData);
                    break;
                case "Same Direction":
                    this.processSameDirection(IncomingData);
                    break;
                case "Tick":
                    this.processTick(IncomingData);
                    break;
                default:
                    console.log(IncomingData);
                    break;
            }
        };
    },
    mounted() {
        this.overlay = true;
        const websocketUrl = document
            .querySelector("body")
            .getAttribute("socketurl")
            ? document.querySelector("body").getAttribute("socketurl") +
              "/publisher-monitor-svc"
            : process.env.VUE_APP_SOCKET_ALERT;

        // establish socket connection
        this.monitorSocket = new WebSocket(websocketUrl);

        // send token to backend websocket on initial status
        this.monitorSocket.onopen = function () {
            const data = {
                token: localStorage.getItem("token"),
                externalMsgType: "CMD_AUTHORIZATION",
            };
            self.monitorSocket.send(JSON.stringify(data));
        };

        const self = this;

        // on socket message coming
        this.monitorSocket.onmessage = function (evt) {
            let msg = evt.data;

            msg = msg.replace("$_", "");

            // decode data
            let decodedMsg = self.unzipVersion(msg);
            // console.log(decodedMsg);
            // if data is in string format, parse it
            if (typeof decodedMsg === "string") {
                decodedMsg = JSON.parse(decodedMsg);
            }

            if (
                decodedMsg.externalMsgType === "RESPONSE_AUTH" &&
                decodedMsg.code === "OK"
            ) {
                const monitorMain = {
                    externalMsgType: "CMD_SUBSCRIBE_MONITORS",
                    monitors: self.monitorList,
                };
                self.monitorSocket.send(JSON.stringify(monitorMain));
            }

            if (decodedMsg.externalMsgType === "DATA_MONITOR_RESULT") {
                if (
                    decodedMsg.monitorType ===
                        "LARGE_EXPOSURE_VOLUME_MONITOR" &&
                    self.alertLists.includes("largeExposureVolume")
                ) {
                    self.processLargeExposureVolume(decodedMsg.panelMap);
                }

                if (
                    decodedMsg.monitorType === "LARGE_EXPOSURE_MONITOR" &&
                    self.alertLists.includes("largeExposure")
                ) {
                    self.processLargeExposure(decodedMsg.panelMap);
                }

                if (
                    decodedMsg.monitorType === "MANDATE_MONITOR" &&
                    self.alertLists.includes("mandate")
                ) {
                    self.processChartMandate(decodedMsg.panelMap);
                }

                if (
                    decodedMsg.monitorType === "LOCKING_POSITION_MONITOR" &&
                    self.alertLists.includes("lockingPosition")
                ) {
                    self.processLockingPosition(decodedMsg.panelMap);
                }

                if (
                    decodedMsg.monitorType === "DAILY_WINNER_LOSER_MONITOR" &&
                    self.alertLists.includes("dailyWinner")
                ) {
                    self.processDailyWinner(decodedMsg.panelMap);
                }

                if (
                    decodedMsg.monitorType === "DAILY_WINNER_LOSER_MONITOR" &&
                    self.alertLists.includes("dailyLoser")
                ) {
                    self.processDailyLoser(decodedMsg.panelMap);
                }
            }
        };

        this.monitorSocket.onclose = (evt) => {
            const websocketUrl =
                process.env.VUE_APP_SOCKET ||
                document.querySelector("body").getAttribute("socketurl") +
                    "/publisher-monitor-svc";

            // establish socket connection
            this.monitorSocket = new WebSocket(websocketUrl);
            console.log("on close: ", evt);
        };

        this.monitorSocket.onerror = (evt) => {
            const websocketUrl =
                process.env.VUE_APP_SOCKET ||
                document.querySelector("body").getAttribute("socketurl") +
                    "/publisher-monitor-svc";

            // establish socket connection
            this.monitorSocket = new WebSocket(websocketUrl);
            console.log("On error: ", evt);
        };
    },
    beforeRouteLeave(to, _1, next) {
        this.toPath = to;
        if (
            this.selectedProfile.id === 0 ||
            this.selectedProfile.positions === undefined ||
            this.moved == false
        ) {
            next();
        } else {
            this.dialog = true;
            this.moveToNext = () => {
                next();
            };
        }
    },
    beforeDestroy() {
        if (this.toPath !== "/signin") {
            const params = {
                meta_value: "alert",
                profile_id: localStorage.getItem("alertId"),
            };

            this.putUserProfileAction(params);
        }
    },
    destroyed() {
        this.monitorSocket.close();
    },
};
</script>

<style scoped>
.vue-grid-item {
    cursor: default !important;
}
.vue-grid-item:not(.vue-grid-placeholder) {
    touch-action: none;
}
.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}
.vue-grid-item > .vue-resizable-handle {
    color: coral;
}
.vdr >>> tr {
    height: 29px;
}
div >>> .handle {
    background: #525252;
    border: 1px solid #525252;
}
div >>> .handle-tm {
    width: 100%;
    height: 5px;
    left: 5px;
    top: -5px;
}
div >>> .handle-bm {
    width: 100%;
    height: 5px;
    left: 5px;
    bottom: -5px;
}
div >>> .handle-mr {
    height: 100%;
    top: 5px;
    width: 5px;
    right: -5px;
}
div >>> .handle-ml {
    height: 100%;
    top: 5px;
    width: 5px;
    left: -5px;
}
div >>> .handle-tl {
    border-radius: 50%;
    top: -5px;
    left: -5px;
}
div >>> .handle-tr {
    border-radius: 50%;
    top: -5px;
    right: -5px;
}
div >>> .handle-bl {
    border-radius: 50%;
    bottom: -5px;
    left: -5px;
}
div >>> .handle-br {
    border-radius: 50%;
    bottom: -5px;
    right: -5px;
}
</style>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr {
    white-space: nowrap;
}
</style>
