<template>
    <div>
        <v-card outlined v-show="showAlert" class="v-card-border-none">
            <v-card-title class="pt-2 pb-2 v-card-custom-title">
                <div
                    class="text-left v-card-custom-title-left"
                    style="width: 60%"
                >
                    <template v-if="!VUETIFY_BREAKPOINT.mobile">
                        <span style="white-space: nowrap"
                            >Deposit Withdrawal Alert<v-tooltip
                                right
                                v-if="configWarning"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        color="red"
                                        small
                                        style="margin-left: 2px"
                                        >mdi-information-outline</v-icon
                                    >
                                </template>
                                <span
                                    >No config for Deposit Withdrawal Alert can
                                    be found.</span
                                >
                            </v-tooltip></span
                        >
                    </template>
                </div>
                <div
                    class="text-right v-card-custom-title-right"
                    style="width: 40%"
                >
                    <v-btn
                        icon
                        x-small
                        :loading="loading"
                        color="primary"
                        v-on:click="refreshData()"
                        style="margin-right: 2px"
                        class="alertRefresh"
                        ><v-icon>mdi-refresh</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        x-small
                        color="#d35400"
                        v-on:click="toggleDateFilter()"
                        style="margin-right: 2px"
                        class="alertCalender"
                        ><v-icon>mdi-calendar</v-icon>
                    </v-btn>
                    <template v-if="!VUETIFY_BREAKPOINT.mobile">
                        <download-excel
                            :data="filteredAlertData"
                            :fields="csvHeader"
                            style="display: inline"
                            type="csv"
                            name="Deposit_Withdrawal.csv"
                            :escapeCsv="false"
                        >
                            <v-btn
                                icon
                                x-small
                                :disabled="filteredAlertData.length === 0"
                                color="green"
                                style="margin-right: 2px"
                                class="alertDownload"
                                ><v-icon>mdi-microsoft-excel</v-icon>
                            </v-btn>
                        </download-excel>
                        <v-btn
                            icon
                            x-small
                            color="blue-grey darken-1"
                            v-on:click="
                                getConfigForAlertAction('Deposit Withdrawal')
                            "
                            style="margin-right: 2px"
                            v-if="showConfig"
                            class="alertConfig"
                            ><v-icon>mdi-cog</v-icon>
                        </v-btn>
                        {{ " " }}
                        <v-icon
                            small
                            style="color: grey"
                            @click="$emit('onRemove')"
                            class="alertClose"
                            >mdi-close-thick</v-icon
                        >
                    </template>
                </div>
            </v-card-title>

            <v-data-table
                :height="height - 120"
                dense
                item-key="name"
                fixed-header
                style="max-width: 650px"
                :headers="alertHeader"
                :items="filteredAlertData"
                class="elevation-0 alert-datatable"
                :items-per-page.sync="offset"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :customSort="(item) => item"
                :header-props="{ sortIcon: null }"
                :page.sync="currentPage"
                :server-items-length="alertDataAmount"
                :mobile-breakpoint="0"
                :footer-props="{
                    pagination: {
                        page: currentPage,
                        itemsPerPage: offset,
                        pageStart: (currentPage - 1) * offset,
                        pageStop: currentPage * offset,
                        pageCount: Math.ceil(alertDataAmount / offset),
                        itemsLength: alertDataAmount,
                    },
                    'items-per-page-text': '',
                    'items-per-page-options': [30, 50, 100, 300, 500, 1000],
                }"
            >
                <template v-slot:item="{ item }">
                    <tr @mouseover="updateRow(item)">
                        <td column="login">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                        {{
                                            item.server_name + ":" + item.login
                                        }}
                                    </div>
                                </template>
                                <span
                                    v-html="extractAlertInfo(item)"
                                ></span> </v-tooltip
                            ><v-icon v-show="item.new == true"
                                >mdi-new-box</v-icon
                            >
                        </td>
                        <td
                            column="name"
                            align="left"
                            v-if="
                                selectedProfile.settings['depositWithdrawal'].name
                                    .showing
                            "
                        >
                            {{ item.name }}
                        </td>
                        <td
                            column="amount"
                            align="right"
                            v-if="
                                selectedProfile.settings['depositWithdrawal']
                                    .amount.showing || false
                            "
                        >
                            {{ numberWithCommas(item.amount.toFixed(2)) }}
                        </td>
                        <td
                            column="book_name"
                            v-if="
                                selectedProfile.settings['depositWithdrawal']
                                    .book_name.showing || false
                            "
                        >
                            {{ item.book_name }}
                        </td>
                        <td
                            column="action"
                            v-if="
                                selectedProfile.settings['depositWithdrawal']
                                    .action.showing || false
                            "
                        >
                            <span v-if="item.amount > 0">Deposit</span>
                            <span v-if="item.amount < 0">Withdrawal</span>
                        </td>
                        <td
                            column="trigger_time"
                            v-if="
                                selectedProfile.settings['depositWithdrawal']
                                    .trigger_time.showing || false
                            "
                        >
                            {{ item.trigger_time }}
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="visible" width="400" :hide-overlay="false">
            <v-card>
                <v-card-title class="text-h5">Filter Date</v-card-title>
                <v-card-text
                    >Choose date to filter history alerts data.</v-card-text
                >
                <v-container>
                    <v-row>
                        <v-col
                            lg="12"
                            md="12"
                            xs="12"
                            cols="12"
                            style="text-align: center"
                        >
                            <v-date-picker v-model="recordDate"></v-date-picker>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text v-on:click="refreshData()"
                        >Ok</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { helper } from "@components/mixins/helper";
import common from "@assets/js/common";
import deepClone from "deep-clone";
import { getConfig } from "@services/alert/config";

export default {
    mixins: [helper],
    props: {
        brokerId: null,
        broker: null,
        modules: null,
        height: 0,
        showing: false,
        showConfig: false,
    },
    data() {
        return {
            visible: false,
            showAlert: true,
            showMonitor: false,
            sortBy: "trigger_time",
            sortDesc: true,
            recordDate: common.getMT4TimeString().split(" ")[0],
            alertInterval: null,

            timeout: null,
            offset: 30,
            currentPage: 1,
            configWarning: false,
        };
    },
    computed: {
        ...mapState("DepositWithdrawal", [
            "alertHeader",
            "alertData",
            "monitorData",
            "monitorHeader",
            "loading",
            "csvHeader",
            "alertDataAmount",
        ]),
        ...mapState("Alert", ["selectedProfile", "selectedPeriod", "books"]),
        filteredAlertData() {
            if (!!this.selectedProfile.unselectedBooks) {
                if (this.selectedProfile.unselectedBooks.length === 0) {
                    return this.alertData;
                } else {
                    let filtered = [];
                    this.alertData.map((data) => {
                        if (
                            !(
                                data.book_name.split(",").length == 1 &&
                                this.selectedProfile.unselectedBooks.includes(
                                    data.book_name
                                )
                            )
                        ) {
                            filtered.push(data);
                        }
                    });
                    return filtered;
                }
            }
            return this.alertData;
        },
    },
    watch: {
        selectedProfile(nv) {
            const settings = nv.settings["depositWithdrawal"];
            const columnName = Object.keys(settings);
            let newHeader = deepClone(this.alertHeader);
            newHeader.map((item) => {
                if (columnName.includes(item.value)) {
                    item.align = settings[item.value]?.showing
                        ? ["amount"].includes(item.value)
                            ? "right"
                            : "left"
                        : " d-none";
                }
            });
            this.UPDATE_ALERT_HEADER(newHeader);
        },
        showing(nv) {
            if (nv) {
                getConfig("deposit-withdrawal").then(({ data }) => {
                    if (data.length === 0) {
                        this.configWarning = true;
                    }
                });
                this.getAlertData();

                if (this.selectedPeriod > 1) {
                    this.alertInterval = setInterval(() => {
                        this.getAlertData();
                    }, this.selectedPeriod * 1000);
                }
            } else {
                clearInterval(this.alertInterval);
            }
        },
        selectedPeriod(nv) {
            if (this.showing) {
                clearInterval(this.alertInterval);
                if (nv !== 0) {
                    this.alertInterval = setInterval(() => {
                        this.getAlertData();
                    }, nv * 1000);
                }
            }
        },
        sortBy() {
            this.getAlertData();
        },
        sortDesc() {
            this.getAlertData();
        },
        offset() {
            this.getAlertData();
        },
        currentPage() {
            this.getAlertData();
        },
    },
    methods: {
        ...mapMutations("DepositWithdrawal", [
            "UPDATE_ALERT_HEADER",
            "UPDATE_ALERT_DATA",
            "UPDATE_SELECTED_DATE",
        ]),
        ...mapActions("Config", ["getConfigForAlertAction"]),
        ...mapActions("DepositWithdrawal", ["getDataDepositWithdrawalAction"]),
        /**
         * Extract fast trade info
         * @param   {[type]}  data  [data description]
         * @return  {[type]}        [return description]
         */
        extractAlertInfo(data) {
            let result = "";

            result +=
                "<p class='mb-2'>Server Name: " + data.server_name + "</p>";
            result +=
                "<p class='mb-2'>Login: " + data.login?.toString() + "</p>";
            result +=
                "<p class='mb-2'>Amount: " + data.amount?.toString() + "</p>";
            result +=
                "<p class='mb-2'>Trigger Time: " + data.trigger_time + "</p>";
            return result;
        },
        /**
         * Open date picker modal
         * @return  {[type]}  [return description]
         */
        toggleDateFilter() {
            this.visible = true;
        },
        /**
         * Refresh alert data
         * @return  {[type]}  [return description]
         */
        refreshData() {
            this.visible = false;
            this.getAlertData();
        },
        /**
         * Get alet data
         * @return  {[type]}  [return description]
         */
        getAlertData() {
            this.timeout && clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.UPDATE_SELECTED_DATE(this.recordDate);
                const selectedBooks = this.books.filter(
                    (element) =>
                        !this.selectedProfile.unselectedBooks.includes(element)
                );
                const sort_by =
                    typeof this.sortBy === "object"
                        ? this.sortBy[0]
                        : this.sortBy;

                let params = {
                    start_time: this.recordDate + " 00:00:00",
                    end_time: this.recordDate + " 23:59:59",
                    offset: this.offset,
                    page: this.currentPage,
                    sort_by: sort_by === "action" ? "amount" : sort_by,
                    sortDesc:
                        typeof this.sortDesc === "object"
                            ? this.sortDesc[0]
                            : this.sortDesc,
                    selected_book: selectedBooks.join(","),
                };
                this.getDataDepositWithdrawalAction(params);
            }, 100);
        },
        /**
         * Change tabs
         * @param   {[type]}  loadModules  [loadModules description]
         * @return  {[type]}               [return description]
         */
        changeTab(loadModules) {
            this.showAlert = false;
            this.showMonitor = false;
            if (loadModules == 1) this.showMonitor = true;
            if (loadModules == 2) this.showAlert = true;
        },
        /**
         * Remove string prefix
         *
         * @param   {[type]}  name  [name description]
         * @return  {[type]}        [return description]
         */
        removePrefix(name) {
            return name.split(":")[1];
        },
        updateRow(row) {
            if (row.new) {
                const index = this.alertData.indexOf(row);
                this.alertData[index].new = false;
                this.UPDATE_ALERT_DATA(this.alertData);
            }
        },
    },
    mounted() {
        if (!!this.VUETIFY_BREAKPOINT.mobile) {
            this.getAlertData();
        }
    },
    destroyed() {
        clearInterval(this.alertInterval);
    },
};
</script>
