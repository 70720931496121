<template>
    <div>
        <v-card outlined v-show="showAlert" class="v-card-border-none">
            <v-card-title class="pt-2 pb-2 v-card-custom-title">
                <div
                    class="text-left v-card-custom-title-left"
                    style="width: 60%"
                >
                    <template v-if="!VUETIFY_BREAKPOINT.mobile">
                        <span style="white-space: nowrap"
                            >Locking Position Alert<v-tooltip
                                right
                                v-if="configWarning"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        color="red"
                                        small
                                        style="margin-left: 2px"
                                        >mdi-information-outline</v-icon
                                    >
                                </template>
                                <span
                                    >No config for Locking Position Alert can be
                                    found.</span
                                >
                            </v-tooltip></span
                        >
                    </template>
                </div>
                <div
                    class="text-right v-card-custom-title-right"
                    style="width: 40%"
                >
                    <template v-if="!VUETIFY_BREAKPOINT.mobile">
                        <download-excel
                            :data="filteredAlertData"
                            :fields="csvHeader"
                            style="display: inline"
                            type="csv"
                            name="Locking_Position.csv"
                            class="alertDownload"
                            :escapeCsv="false"
                        >
                            <v-btn
                                icon
                                x-small
                                :disabled="filteredAlertData.length === 0"
                                color="green"
                                style="margin-right: 2px"
                                ><v-icon>mdi-microsoft-excel</v-icon>
                            </v-btn>
                        </download-excel>
                        <v-btn
                            icon
                            x-small
                            color="blue-grey darken-1"
                            v-on:click="
                                getConfigForAlertAction('Locking Position')
                            "
                            style="margin-right: 2px"
                            v-if="showConfig"
                            class="alertConfig"
                            ><v-icon>mdi-cog</v-icon>
                        </v-btn>
                        {{ " " }}
                        <v-icon
                            small
                            style="color: grey"
                            @click="$emit('onRemove')"
                            class="alertClose"
                            >mdi-close-thick</v-icon
                        >
                    </template>
                </div>
            </v-card-title>

            <v-data-table
                style="max-width: 550px"
                dense
                item-key="name"
                :customSort="customSort"
                :items="filteredAlertData"
                :headers="alertHeader"
                class="elevation-0 alert-datatable"
                :items-per-page.sync="offset"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :height="height - 120"
                fixed-header
                :header-props="{ sortIcon: null }"
                :page.sync="currentPage"
                :mobile-breakpoint="0"
                :footer-props="{
                    'items-per-page-text': '',
                    'items-per-page-options': [30, 50, 100, 300, 500, 1000],
                }"
            >
                <template v-slot:item="{ item }">
                    <tr>
                        <td column="login">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <a
                                        v-bind="attrs"
                                        v-on="on"
                                        v-on:click="loadDialog(item)"
                                        v-if="showHomeLoginDetailSummary"
                                        style="
                                            display: inline-block;
                                            height: 21px;
                                        "
                                        >{{ extraUserInfo(item.user) }}</a
                                    >

                                    <div v-bind="attrs" v-on="on" v-else>
                                        {{ extraUserInfo(item.user) }}
                                    </div>
                                </template>
                                <span
                                    v-html="extractAlertInfo(item)"
                                ></span> </v-tooltip
                            ><v-icon v-show="item.new == true"
                                >mdi-new-box</v-icon
                            >
                        </td>
                        <td
                            column="name"
                            align="left"
                            v-if="
                                selectedProfile.settings['lockingPosition'].name
                                    .showing
                            "
                        >
                            {{ item.name }}
                        </td>
                        <td
                            column="symbol"
                            v-if="
                                selectedProfile.settings['lockingPosition']
                                    .symbol.showing
                            "
                        >
                            {{ item.symbol }}
                        </td>
                        <td
                            column="books"
                            v-if="
                                selectedProfile.settings['lockingPosition']
                                    .books.showing
                            "
                        >
                            {{ item.books }}
                        </td>
                        <td
                            column="locking_position"
                            align="right"
                            v-if="
                                selectedProfile.settings['lockingPosition']
                                    .locking_position.showing
                            "
                        >
                            {{
                                numberWithCommas(item.lockingVolume.toFixed(2))
                            }}
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>

        <LoginSummaryDialog
            v-if="dialog"
            :fullLogin="fullLoginValue"
            :login="loginValue"
            :server="serverValue"
            :symbol="symbolValue"
        />
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import LoginSummaryDialog from "@components/login-summary/ComponentLoginSummaryDialog";
import { helper } from "@components/mixins/helper";
import common from "@assets/js/common";
import deepClone from "deep-clone";
import { permission } from "@components/mixins/permission";
import { getConfig } from "@services/alert/config";

export default {
    mixins: [helper, permission],
    components: { LoginSummaryDialog },
    props: {
        brokerId: null,
        broker: null,
        modules: null,
        height: 0,
        showing: false,
        showConfig: false,
    },
    data() {
        return {
            visible: false,
            showAlert: true,
            showMonitor: false,
            dialog: false,
            sortBy: "lockingVolume",
            sortDesc: false,
            recordDate: common.getMT4TimeString().split(" ")[0],
            showHomeLoginDetailSummary: false,
            fullLoginValue: null,
            loginValue: null,
            serverValue: null,
            symbolValue: null,
            offset: 30,
            currentPage: 1,
            configWarning: false,
        };
    },
    computed: {
        ...mapState("LockingPosition", [
            "alertHeader",
            "alertData",
            "loading",
            "csvHeader",
        ]),
        ...mapState("Alert", ["selectedProfile", "selectedPeriod", "books"]),
        ...mapState("SettingsServer", ["serverData"]),
        filteredAlertData() {
            if (!!this.selectedProfile.unselectedBooks) {
                if (this.selectedProfile.unselectedBooks.length === 0) {
                    return this.bookSelectionFilterData;
                } else {
                    let filtered = [];
                    this.bookSelectionFilterData.map((data) => {
                        if (
                            !(
                                data.books.split(",").length == 1 &&
                                this.selectedProfile.unselectedBooks.includes(
                                    data.books
                                )
                            )
                        ) {
                            filtered.push(data);
                        }
                    });
                    return filtered;
                }
            }
            return this.bookSelectionFilterData;
        },
        bookSelectionFilterData() {
            let filtered = [];
            this.alertData.map((data) => {
                if (
                    data.books.split(",").length == 1 &&
                    this.books.includes(data.books)
                ) {
                    filtered.push(data);
                }
            });
            return filtered;
        },
    },
    watch: {
        showing(nv) {
            if (nv) {
                getConfig("locking-position").then(({ data }) => {
                    if (data.length === 0) {
                        this.configWarning = true;
                    }
                });
            }
        },

        selectedProfile(nv) {
            const settings = nv.settings["lockingPosition"];
            const columnName = Object.keys(settings);
            let newHeader = deepClone(this.alertHeader);
            newHeader.map((item) => {
                if (columnName.includes(item.value)) {
                    item.align = settings[item.value].showing
                        ? ["locking_position"].includes(item.value)
                            ? "right"
                            : "left"
                        : " d-none";
                }
            });
            this.UPDATE_ALERT_HEADER(newHeader);
        },
    },
    methods: {
        ...mapMutations("LockingPosition", [
            "UPDATE_ALERT_HEADER",
            "UPDATE_ALERT_DATA",
            "UPDATE_SELECTED_DATE",
        ]),
        ...mapActions("LockingPosition", ["getDataLockingPositionAction"]),
        ...mapActions("Config", ["getConfigForAlertAction"]),
        customSort(items, sortBy, sortDesc) {
            items.sort((a, b) => {
                if (!sortDesc[0]) {
                    return a[sortBy] < b[sortBy] ? -1 : 1;
                } else {
                    return b[sortBy] < a[sortBy] ? -1 : 1;
                }
            });
            return items;
        },
        /**
         * Get user info
         * @param {*} user
         */
        extraUserInfo(user) {
            return user.split(":")[1] + ":" + user.split(":")[2];
        },
        /**
         * Extract fast trade info
         * @param   {[type]}  data  [data description]
         * @return  {[type]}        [return description]
         */
        extractAlertInfo(data) {
            let result = "";

            // result +=
            //     "<p class='mb-2'>Server Name: " + data.server_name + "</p>";
            result +=
                "<p class='mb-2'>Symbol: " + data.symbol.toString() + "</p>";
            result +=
                "<p class='mb-2'>Locking Position: " +
                data.lockingVolume.toString() +
                "</p>";
            result +=
                "<p class='mb-2'>Locking Position Threshold: " +
                data.lockingVolumeThreshold.toString() +
                "</p>";

            return result;
        },
        /**
         * Extra user infomation
         * @param {*} user
         */
        extraUserInfo(user) {
            return user.split(":")[1] + ":" + user.split(":")[2];
        },
        /**
         * Remove string prefix
         *
         * @param   {[type]}  name  [name description]
         * @return  {[type]}        [return description]
         */
        removePrefix(name) {
            return name.split(":")[2];
        },
        loadDialog(item) {
            this.fullLoginValue = item.user;
            this.loginValue = item.user.split(":")[2].toString();
            this.serverValue = item.user.split(":")[1].toString();
            this.symbolValue = item.symbol.split(".")[0];
            this.dialog = false;
            this.$nextTick(() => {
                this.dialog = true;
            });
        },
    },
    created() {
        const userFunction = this.getFunction("book");

        if (userFunction.includes("homeLoginDetailSummary")) {
            this.showHomeLoginDetailSummary = true;
        }
    },
};
</script>
