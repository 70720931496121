var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAlert),expression:"showAlert"}],staticClass:"v-card-border-none",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pt-2 pb-2 v-card-custom-title"},[_c('div',{staticClass:"text-left v-card-custom-title-left",staticStyle:{"width":"60%"}},[(!_vm.VUETIFY_BREAKPOINT.mobile)?[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("Locking Position Alert"),(_vm.configWarning)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"margin-left":"2px"},attrs:{"color":"red","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,false,3366656581)},[_c('span',[_vm._v("No config for Locking Position Alert can be found.")])]):_vm._e()],1)]:_vm._e()],2),_c('div',{staticClass:"text-right v-card-custom-title-right",staticStyle:{"width":"40%"}},[(!_vm.VUETIFY_BREAKPOINT.mobile)?[_c('download-excel',{staticClass:"alertDownload",staticStyle:{"display":"inline"},attrs:{"data":_vm.filteredAlertData,"fields":_vm.csvHeader,"type":"csv","name":"Locking_Position.csv","escapeCsv":false}},[_c('v-btn',{staticStyle:{"margin-right":"2px"},attrs:{"icon":"","x-small":"","disabled":_vm.filteredAlertData.length === 0,"color":"green"}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)],1),(_vm.showConfig)?_c('v-btn',{staticClass:"alertConfig",staticStyle:{"margin-right":"2px"},attrs:{"icon":"","x-small":"","color":"blue-grey darken-1"},on:{"click":function($event){return _vm.getConfigForAlertAction('Locking Position')}}},[_c('v-icon',[_vm._v("mdi-cog")])],1):_vm._e(),_vm._v(" "+_vm._s(" ")+" "),_c('v-icon',{staticClass:"alertClose",staticStyle:{"color":"grey"},attrs:{"small":""},on:{"click":function($event){return _vm.$emit('onRemove')}}},[_vm._v("mdi-close-thick")])]:_vm._e()],2)]),_c('v-data-table',{staticClass:"elevation-0 alert-datatable",staticStyle:{"max-width":"550px"},attrs:{"dense":"","item-key":"name","customSort":_vm.customSort,"items":_vm.filteredAlertData,"headers":_vm.alertHeader,"items-per-page":_vm.offset,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"height":_vm.height - 120,"fixed-header":"","header-props":{ sortIcon: null },"page":_vm.currentPage,"mobile-breakpoint":0,"footer-props":{
                'items-per-page-text': '',
                'items-per-page-options': [30, 50, 100, 300, 500, 1000],
            }},on:{"update:itemsPerPage":function($event){_vm.offset=$event},"update:items-per-page":function($event){_vm.offset=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('tr',[_c('td',{attrs:{"column":"login"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(_vm.showHomeLoginDetailSummary)?_c('a',_vm._g(_vm._b({staticStyle:{"display":"inline-block","height":"21px"},on:{"click":function($event){return _vm.loadDialog(item)}}},'a',attrs,false),on),[_vm._v(_vm._s(_vm.extraUserInfo(item.user)))]):_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.extraUserInfo(item.user))+" ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.extractAlertInfo(item))}})]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.new == true),expression:"item.new == true"}]},[_vm._v("mdi-new-box")])],1),(
                            _vm.selectedProfile.settings['lockingPosition'].name
                                .showing
                        )?_c('td',{attrs:{"column":"name","align":"left"}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(
                            _vm.selectedProfile.settings['lockingPosition']
                                .symbol.showing
                        )?_c('td',{attrs:{"column":"symbol"}},[_vm._v(" "+_vm._s(item.symbol)+" ")]):_vm._e(),(
                            _vm.selectedProfile.settings['lockingPosition']
                                .books.showing
                        )?_c('td',{attrs:{"column":"books"}},[_vm._v(" "+_vm._s(item.books)+" ")]):_vm._e(),(
                            _vm.selectedProfile.settings['lockingPosition']
                                .locking_position.showing
                        )?_c('td',{attrs:{"column":"locking_position","align":"right"}},[_vm._v(" "+_vm._s(_vm.numberWithCommas(item.lockingVolume.toFixed(2)))+" ")]):_vm._e()])]}}])})],1),(_vm.dialog)?_c('LoginSummaryDialog',{attrs:{"fullLogin":_vm.fullLoginValue,"login":_vm.loginValue,"server":_vm.serverValue,"symbol":_vm.symbolValue}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }