<template>
    <div>
        <v-card outlined class="v-card-border-none">
            <v-card-title class="pt-2 pb-2 v-card-custom-title">
                <div class="text-left v-card-custom-title-left">
                    <template v-if="!VUETIFY_BREAKPOINT.mobile">
                        <span style="white-space: nowrap">
                            Tick Alert
                            <v-tooltip right v-if="!!configWarning">
                                <template v-slot:activator="{ on, attrs }">
                                    <b
                                        v-bind="attrs"
                                        v-on="on"
                                        style="margin-left: 2px; color: red"
                                        >{{
                                            configWarning > 100
                                                ? "99+"
                                                : configWarning
                                        }}</b
                                    >
                                </template>
                                <span>
                                    There are {{ configWarning }} symbols does
                                    not have config for Tick Alert.</span
                                >
                            </v-tooltip>
                        </span>
                    </template>
                </div>
                <div class="text-right v-card-custom-title-right">
                    <v-btn
                        icon
                        x-small
                        :loading="loading"
                        color="primary"
                        v-on:click="refreshData()"
                        style="margin-right: 2px"
                        class="alertRefresh"
                        ><v-icon>mdi-refresh</v-icon>
                    </v-btn>
                    <template v-if="!VUETIFY_BREAKPOINT.mobile">
                        <download-excel
                            :data="alertData"
                            :fields="csvHeader"
                            style="display: inline"
                            type="csv"
                            name="Tick.csv"
                            :escapeCsv="false"
                        >
                            <v-btn
                                icon
                                x-small
                                :disabled="alertData.length === 0"
                                color="green"
                                style="margin-right: 2px"
                                class="alertDownload"
                                ><v-icon>mdi-microsoft-excel</v-icon>
                            </v-btn>
                        </download-excel>
                        <v-btn
                            icon
                            x-small
                            color="blue-grey darken-1"
                            v-on:click="getConfigForAlertAction('Tick')"
                            style="margin-right: 2px"
                            v-if="showConfig"
                            class="alertConfig"
                            ><v-icon>mdi-cog</v-icon>
                        </v-btn>
                        {{ " " }}
                        <v-icon
                            small
                            style="color: grey"
                            @click="$emit('onRemove')"
                            class="alertClose"
                            >mdi-close-thick</v-icon
                        >
                    </template>
                </div>
            </v-card-title>

            <v-data-table
                style="max-width: 1040px"
                :mobile-breakpoint="0"
                dense
                item-key="name"
                disable-sort
                :customSort="(item) => item"
                :items="alertData"
                :headers="alertHeader"
                class="elevation-0 alert-datatable"
                :items-per-page="offset"
                :height="height - 120"
                :header-props="{ sortIcon: null }"
                fixed-header
                :server-items-length="alertDataAmount"
                :items-per-page.sync="offset"
                :page.sync="currentPage"
                :footer-props="{
                    pagination: {
                        page: currentPage,
                        itemsPerPage: offset,
                        pageStart: (currentPage - 1) * offset,
                        pageStop: currentPage * offset,
                        pageCount: Math.ceil(alertDataAmount / offset),
                        itemsLength: alertDataAmount,
                    },
                    'items-per-page-text': '',
                    'items-per-page-options': [30, 50, 100, 300, 500, 1000],
                }"
            >
                <template v-slot:item="{ item }">
                    <tr @mouseover="updateRow(item)">
                        <td column="server">
                            {{ item.server_name
                            }}<v-icon v-show="item.new == true"
                                >mdi-new-box</v-icon
                            >
                        </td>
                        <td column="symbol">
                            {{ item.symbol }}
                        </td>
                        <td column="security">
                            {{ item.security }}
                        </td>
                        <td column="seconds_threshold" align="right">
                            {{ item.seconds_threshold }}s
                        </td>
                        <td column="gap" align="right">{{ item.gap }}s</td>
                        <td column="action" align="left">
                            <p
                                v-if="
                                    item.action === 'required' && !allowMutation
                                "
                                class="ma-0"
                            >
                                mute
                            </p>
                            <a
                                v-else-if="
                                    item.action === 'required' &&
                                    !!allowMutation
                                "
                                text
                                v-on:click="() => updateSymbol(item)"
                            >
                                mute
                                <v-btn
                                    icon
                                    x-small
                                    color="primary"
                                    :loading="item.loading"
                                    v-if="item.loading"
                                    ><v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </a>
                            <p v-else class="ma-0">
                                processing
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" small>
                                            mdi-account
                                        </v-icon>
                                    </template>
                                    <span
                                        >By {{ accountName(item.action) }}</span
                                    >
                                </v-tooltip>
                            </p>
                        </td>
                        <td column="last_update_time">
                            {{ item.last_update_time }}
                        </td>

                        <td column="current_time">
                            {{ item.current_time }}
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>

        <LoginSummaryDialog
            v-if="dialog"
            :login="selectedLogin"
            :brokerId="selctedBrokerId"
            :brokerName="selectedBrokerName"
        />
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import LoginSummaryDialog from "@components/login-summary/ComponentLoginSummaryDialog";
import { helper } from "@components/mixins/helper";
import { getTickMissingConfig } from "@services/alert/config";

export default {
    mixins: [helper],
    components: { LoginSummaryDialog },
    props: {
        brokerId: null,
        broker: null,
        modules: null,
        height: 0,
        showing: false,
        showConfig: false,
    },
    data() {
        return {
            timeout: null,
            visible: false,
            selctedBrokerId: this.brokerId,
            selectedBrokerName: this.broker,
            dialog: false,
            selectedLogin: 0,
            timer: null,
            alertInterval: null,
            offset: 30,
            currentPage: 1,
            configWarning: 0,
            allowMutation: false,
        };
    },
    computed: {
        ...mapState("Tick", [
            "alertData",
            "alertHeader",
            "loading",
            "csvHeader",
            "alertDataAmount",
        ]),
        ...mapState("Alert", ["selectedProfile", "selectedPeriod"]),
        ...mapState("UserManagement", ["listUser"]),
    },
    watch: {
        selectedProfile(nv) {
            // this.getAlertData();
        },
        showing(nv) {
            if (nv) {
                getTickMissingConfig().then(({ data }) => {
                    if (data.length > 0) {
                        this.configWarning = data.length;
                    }
                });
                this.getAlertData();

                if (this.selectedPeriod > 1) {
                    this.alertInterval = setInterval(() => {
                        this.getAlertData();
                    }, this.selectedPeriod * 1000);
                }
            } else {
                this.configWarning = 0;
                clearInterval(this.alertInterval);
            }
        },
        selectedPeriod(nv) {
            if (this.showing) {
                clearInterval(this.alertInterval);
                if (nv !== 0) {
                    this.alertInterval = setInterval(() => {
                        this.getAlertData();
                    }, nv * 1000);
                }
            }
        },
        offset() {
            this.getAlertData();
        },
        currentPage() {
            this.getAlertData();
        },
    },
    methods: {
        ...mapMutations("Tick", ["UPDATE_ALERT_HEADER", "UPDATE_ALERT_DATA"]),
        ...mapActions("Tick", ["getTickAction", "updateSymbolAction"]),
        ...mapActions("Config", ["getConfigForAlertAction"]),
        ...mapActions("UserManagement", ["getUsersAction"]),
        accountName(id) {
            return (
                this.listUser?.find(
                    (item) => item?.id.toString() === id.toString()
                )?.username || "Unknown"
            );
        },
        /**
         * Open dialog window
         * @param   {[type]}  login  [login description]
         * @return  {[type]}         [return description]
         */
        loadDialog(user) {
            let login = user;
            this.selectedLogin = login;
            this.dialog = false;
            this.$nextTick(() => {
                this.dialog = true;
            });
        },
        refreshData() {
            this.visible = false;
            this.getAlertData();
        },
        getAlertData() {
            this.timeout && clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                let params = {
                    offset: this.offset,
                    page: this.currentPage,
                };
                this.getTickAction(params);
            }, 100);
        },
        toggleDateFilter() {
            this.visible = true;
        },
        updateRow(row) {
            if (row.new) {
                const index = this.alertData.indexOf(row);
                this.alertData[index].new = false;
                this.UPDATE_ALERT_DATA(this.alertData);
            }
        },
        updateSymbol(row) {
            const index = this.alertData.indexOf(row);
            const params = {
                symbol: row.symbol + ":" + row.server_id,
            };
            this.alertData[index].loading = true;
            this.UPDATE_ALERT_DATA(this.alertData);
            this.updateSymbolAction(params).then((res) => {
                if (res.status === 200) {
                    this.alertData[index].loading = false;
                    this.alertData[index].action = res.data.userId;
                    this.UPDATE_ALERT_DATA(this.alertData);
                }
            });
        },
    },
    mounted() {
        const userFunction = JSON.parse(localStorage.getItem("permission"))
            ?.frontPermission?.alert?.child;
        if (userFunction.includes("tickMutation")) {
            this.allowMutation = true;
        }
        if (!!this.VUETIFY_BREAKPOINT.mobile) {
            this.getAlertData();
        }
        this.getUsersAction();
    },
    destroyed() {
        clearInterval(this.alertInterval);
    },
};
</script>
