var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMonitor),expression:"showMonitor"}],staticClass:"v-card-border-none",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pt-2 pb-2 v-card-custom-title"},[_c('div',{staticClass:"text-left v-card-custom-title-left",staticStyle:{"width":"35%"}},[(!_vm.VUETIFY_BREAKPOINT.mobile)?[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("Mandate Monitor"),(_vm.configWarning)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"margin-left":"2px"},attrs:{"color":"red","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,false,3366656581)},[_c('span',[_vm._v("No config for Mandate Monitor can be found.")])]):_vm._e()],1)]:_vm._e()],2),_c('div',{staticClass:"text-right v-card-custom-title-right",staticStyle:{"width":"65%"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.selectedAmount)+" ")])]}}])},[_c('v-list',_vm._l((_vm.amountOption),function(item,index){return _c('v-list-item',{key:index,attrs:{"dense":""},on:{"click":function($event){_vm.selectedAmount = item}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1),_vm._v(" "+_vm._s(" ")+" "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.selectedChartOption)+" ")])]}}])},[_c('v-list',_vm._l((_vm.chartOption),function(item,index){return _c('v-list-item',{key:index,attrs:{"dense":""},on:{"click":function($event){_vm.selectedChartOption = item}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1),_vm._v(" "+_vm._s(" ")+" "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"alertBookSelection",attrs:{"x-small":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.currentBook)+" ")])]}}])},[_c('v-list',_vm._l((_vm.filteredBookOptions),function(item,index){return _c('v-list-item',{key:index,attrs:{"dense":""},on:{"click":function($event){return _vm.onSelectBook(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1),_vm._v(" "+_vm._s(" ")+" "),_c('v-icon',{attrs:{"text":"","small":"","color":"default","dark":""},on:{"click":_vm.toggleMonitorSortingOrder}},[_vm._v(" "+_vm._s(this.monitorChartAsc ? "mdi-sort-ascending" : "mdi-sort-descending")+" ")]),(!_vm.VUETIFY_BREAKPOINT.mobile)?[_vm._v(" "+_vm._s(" ")+" "),(_vm.showConfig)?_c('v-icon',{staticClass:"alertConfig",attrs:{"text":"","small":"","color":"blue-grey darken-1"},on:{"click":function($event){return _vm.getConfigForAlertAction('Mandate')}}},[_vm._v("mdi-cog")]):_vm._e(),_vm._v(" "+_vm._s(" ")+" "),_c('v-icon',{staticClass:"alertClose",staticStyle:{"color":"grey"},attrs:{"small":""},on:{"click":function($event){return _vm.$emit('onRemove')}}},[_vm._v("mdi-close-thick")])]:_vm._e()],2)]),_c('ChartMandate',{attrs:{"isAscending":_vm.monitorChartAsc,"chartOption":_vm.selectedChartOption,"amountOption":_vm.selectedAmount,"height":_vm.height}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }